import React from 'react';
import FileViewer from 'react-file-viewer';
import PDF from 'react-pdf-js';
import getParams from "./utils"
import "./App.css"

class App extends React.Component {

  state = {
    pageNo: 1,
    scale: 0.7,
    totalPage: 0,
    upPage: ''
  }

  componentDidMount() {
    if (!getParams("nickname") && !getParams("mobile")) {
      return false;
    }

    // 屏蔽热键
    window.document.onkeydown = function (event) {
      //屏蔽F12
      if (window.event && window.event.keyCode === 123) {
        event.returnValue = false;
        return false;
      }
      // 屏蔽 Ctrl+c
      if (event.ctrlKey && event.keyCode === 67) event.returnValue = false;
    }

    let video = document.getElementsByTagName("video")[0];
    if (video) {
      video.setAttribute("controlslist", "nodownload")
    }
  }

  onError = (e) => {
    console.log(e, "2341242314");
  }

  getTotalPage = totalPage => {
    this.setState({
      totalPage
    })
  }

  nextPage = () => {
    let { pageNo, totalPage } = this.state;
    pageNo++;
    this.setState({
      pageNo: pageNo >= totalPage ? totalPage : pageNo,
      upPage: ""
    })
  }

  prevPage = () => {
    let { pageNo } = this.state;
    pageNo--;
    this.setState({
      pageNo: pageNo <= 1 ? 1 : pageNo,
      upPage: ""
    })
  }

  goLastPage = () => {
    let { totalPage } = this.state;
    this.setState({
      pageNo: totalPage
    })
  }

  goToPageEvent = () => {
    let { totalPage, upPage } = this.state;

    if (upPage >= 1 && upPage <= totalPage) {
      this.setState({
        pageNo: Number(upPage)
      })
    } else {
      alert("超出跳转范围！");
    }
  }
  render() {
    let url = getParams("url"),
      type = getParams("type");
    let { upPage } = this.state;
    return (
      <div id="apps">
        {
          // 清晰的pdf
          type === 'pdf' ?
            <div style={{ padding: "0 10px" }}>
              <PDF file={url} onDocumentComplete={this.getTotalPage} scale={this.state.scale} page={this.state.pageNo} />
              <div className="operation">
                <div>
                  <button onClick={() => this.setState({ pageNo: 1 })}>首页</button>
                  <button onClick={this.prevPage}>上一页</button>
                  <button onClick={this.nextPage}>下一页</button>
                  <button onClick={this.goLastPage}>尾页</button>
                  &nbsp;&nbsp; 跳到<input onChange={({ target: { value } }) => this.setState({ upPage: value })} value={upPage} type="text" />页 &nbsp;
                  <button onClick={this.goToPageEvent}>确定</button>&nbsp;&nbsp;
                  <span style={{ fontSize: 14, color: '#333' }}>第{this.state.pageNo}页 / 共{this.state.totalPage ? this.state.totalPage : 0}页</span>
                </div>
                <div style={{ marginTop: 20 }}>
                  <button
                    onClick={() => {
                      let { scale } = this.state;
                      scale += 0.1
                      this.setState({
                        scale
                      })
                    }}
                  >放大</button>
                  <button
                    onClick={() => {
                      let { scale } = this.state;
                      scale -= 0.1
                      this.setState({
                        scale
                      })
                    }}
                  >缩小</button>
                  <button onClick={() => { this.setState({ scale: 0.7 }) }}>恢复</button>
                </div>
              </div>
            </div>
            :
            // mp3音频教案
            type === 'mp3' ?
              <audio className="audio-style" controls autoPlay src={url}></audio>
              :
              // 这个react-file-viewer 插件太特么辣鸡了，移动端pdf显示不清晰 
              <FileViewer
                fileType={type}
                filePath={url}
                onError={this.onError}
                errorComponent={console.log("出现错误")}
                unsupportedComponent={console.log("不支持")}
              />
        }
      </div>
    )
  }
}

export default App;
